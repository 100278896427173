html,
body,
#root {
  height: 100%; /* needed for proper layout */
}

body {
  overflow: hidden;
}

p {
  margin-bottom: 1em;
}

#root {
  display: flex;
  flex-direction: column;
}

#header {
  flex: 0 0 auto;
  background-color: #dcdcdc;
}

#content {
  flex: 1 1 auto;
  position: relative; /* need this to position inner content */
  overflow-y: auto;
}

#footer {
  flex: 0 0 auto;
  background-color: #dcdcdc;
}
